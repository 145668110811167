/* eslint-disable react/jsx-key */
import React, { Component, Suspense } from "react";
import {
  ResponsiveTestimonialTab,
  MenuListV2Main,
  CustomListItemV2Main,
  WrapperContainer,
  ResourcesWrapper,
  Divider,
  PaddingContainer,
  TabsContainerTestimonial,
} from "../WellnessPageV2/styles";
import { ImageUrl, imgPath } from "../../utils/constants";
import {
  ButtonLearnMoree,
  TabData,
  Button2,
  Section,
  IntroTextContainer,
  PosterImage,
  MainResources,
  Resources,
  DemoText,
  WatchDemoCard,
  CoreFeatureSectionHeading,
  CoreWrapper,
  CustomList,
  EmployeeRewardMain,
  EmployeeRewardCard,
  CompanyRewardsProgram,
  RowWrapperV2,
  RowResponsiveWrapperV2,
  CoreListV2,
  RatingWrapperV3,
  WrapperV3,
  RatingWrapper,
  RatingsContainers,
  CoreFeatureSectionHeadingV1,
} from "./styles";
import PropTypes from "prop-types";
import data from "./wellnessResources.json";
import { MenuLinks } from "../NavigationBarV2/styles";
import { ButtonGetStarted, WellBeing } from "../MainDashboardV3/styles";
import testimonialData from "./TestimonialData.json";
import coreData from "./coreFeature.json";
import { TabsContainerV2, MenuListV2Main2 } from "../MainDashboardV3/styles";
import Waiting from "../Waiting";
const DemoPopup = React.lazy(() => import("../WhyAmigoCommon/DemoPopup"));
import LazyImage from "../common/LazyImage/LazyImage";
class RewardPageMain extends Component {
  constructor() {
    super();
    this.state = {
      mobileViewStatus: window.innerWidth <= 500,
      active: "",
      selectedImage: "",
      showVideo: false,
      selectedText: "WHY USERS LOVE US",
      selectedTab: 0,
      wellnessResources: JSON.parse(JSON.stringify(data)),
      image: [
        data[0].data[0].image,
        data[1].data[0].image,
        data[2].data[0].image,
        data[3].data[0].image,
      ],
      email: "",
      tabArray: ["50-200 employees", "201-500 employees", "501-1000 employees"],
      ratingImage: [
        testimonialData[0].imageURL,
        testimonialData[1].imageURL,
        testimonialData[2].imageURL,
      ],
      ratingData: [
        testimonialData[0].data,
        testimonialData[1].data,
        testimonialData[2].data,
      ],
      ratingName: [
        testimonialData[0].head,
        testimonialData[1].head,
        testimonialData[2].head,
      ],
      ratingMain: [
        testimonialData[0].main,
        testimonialData[1].main,
        testimonialData[2].main,
      ],

      coreButtonText: [
        coreData[0].buttonText,
        coreData[1].buttonText,
        coreData[2].buttonText,
        coreData[3].buttonText,
      ],
      coreInfo2: [
        coreData[0].data2,
        coreData[1].data2,
        coreData[2].data2,
        coreData[3].data2,
      ],
      coreInfo: [
        coreData[0].data,
        coreData[1].data,
        coreData[2].data,
        coreData[3].data,
      ],
      coreHead: [
        coreData[0].head,
        coreData[1].head,
        coreData[2].head,
        coreData[3].head,
      ],
      coreName: [
        coreData[0].name,
        coreData[1].name,
        coreData[2].name,
        coreData[3].name,
      ],
      coreImage: [
        coreData[0].img,
        coreData[1].img,
        coreData[2].img,
        coreData[3].img,
      ],
      selectedCoreTab: 0,
      selectedSubTab: 0,
      currentDiv: [true, false, false, false, false, false],
      showtab: 1,
    };
  }

  componentDidMount() {
    this.setState({ active: "Insurance" });
  }

  handleSubTabs = (index) => {
    this.setState({ selectedSubTab: index });
  };

  handleSubResponsiveTabs = (resIndex) => {
    const { currentDiv } = this.state;
    let array = currentDiv;
    array.forEach((data, index) => {
      array[index] = false;
    });
    array[resIndex] = true;
    this.setState({ selectedCoreTab: resIndex });
    this.setState({ currentDiv: array });
    if (resIndex === this.state.selectedCoreTab) {
      this.setState({ showtab: !this.state.showtab });
    }
  };

  changeModal = () => {
    this.setState({ showVideo: !this.state.showVideo });
  };

  top = () => (
    <Section firstChild margin="0">
      <IntroTextContainer>
        <h1>
          Rewards
          <br />
          Simplified
          <br />
        </h1>
        <LazyImage div={"divider"} src={ImageUrl + "/images/RewardsWebImages/sunRectangle.png"}/>
        <p>
          Modernize company rewards with customizable
          {this.state.mobileViewStatus ? "" : <br />}
          features and automated reporting that save you
          {this.state.mobileViewStatus ? "" : <br />}
          time and energy.{this.state.mobileViewStatus ? "" : <br />}
        </p>
        <div className="wrap">
          {/* <ButtonWatchVideo onClick={() => this.changeModal()}>
            <img alt="playbtn" src="/public/images/RewardsWebImages/playPolygonSun.png" />
            Watch Video</ButtonWatchVideo> */}
          <ButtonLearnMoree
            onClick={() => this.props.history.push("/contact")}
            noMargin={1}
          >
            REQUEST A DEMO
          </ButtonLearnMoree>
        </div>
      </IntroTextContainer>
      <PosterImage>
        <picture>
          <source
            srcSet={ImageUrl + "/images/RewardsWebImages/Banner_webp_image.webp"}
            type="image/png"
          />
          <LazyImage src={ImageUrl + "/images/RewardsWebImages/Banner_webp_image.webp"}/>
        </picture>
      </PosterImage>
      <LazyImage src={ImageUrl + "/images/RewardsWebImages/rewards_mobile.png"}/>
    </Section>
  );

  ratingshead = () => (
    <RatingWrapperV3>
      <WrapperV3>
        <h2>Loved by Clients</h2>
        <LazyImage src={ImageUrl + "/images/HomePageV2/line.svg"}/>
      </WrapperV3>
    </RatingWrapperV3>
  );

  ratingsUI = () => (
    <RatingWrapper>
      <RatingsContainers>
        <LazyImage div={"image"} src={ImageUrl + this.state.ratingImage[this.state.selectedTab]} alt={"rating"} />

        <LazyImage div={"ratingStars"} src={ImageUrl + "/images/HomePageV2/ratingStars.png"} alt={"rating"} />
        <div className={"ratingDescription"}>
          {this.state.ratingData[this.state.selectedTab]}
        </div>
        <div className={"ratingBy"}>
          {this.state.ratingName[this.state.selectedTab]}{" "}
          <span>{this.state.ratingMain[this.state.selectedTab]}</span>
        </div>
      </RatingsContainers>
    </RatingWrapper>
  );

  decreaseIndex = () => {
    const { selectedTab } = this.state;
    let temp = selectedTab - 1;
    this.setState({ selectedTab: temp });
  };

  increaseIndex = () => {
    const { selectedTab } = this.state;
    let temp = selectedTab + 1;
    this.setState({ selectedTab: temp });
  };

  handleTabs = (index) => {
    this.setState({ selectedTab: index });
  };

  redirection = () => {
    const { history } = this.props;
    history.push("/demo");
  };

  renderMenuList = () => {
    let TabArray = [
      {
        tabName: "50-200 employees",
        image: "/path/",
        text: "Banking data",
      },
      {
        tabName: "201-500 employees",
        image: "/path/",
        text: "Technology Data",
      },
      {
        tabName: "501-1,000 employees",
        image: "/path/",
        text: "Civil Engineering data",
      },
    ];

    return (
      <TabsContainerTestimonial>
        <MenuListV2Main>
          {TabArray.map((data, index) => (
            <CustomListItemV2Main
              showTab={true}
              active={this.state.selectedTab === index}
              key={index}
              onClick={() => this.handleTabs(index)}
            >
              <MenuLinks>{data.tabName}</MenuLinks>
            </CustomListItemV2Main>
          ))}
        </MenuListV2Main>
      </TabsContainerTestimonial>
    );
  };

  renderTabData = () => (
    <TabData>
      <div className="responsiveWhyButton">
        <Button2
          display={"block"}
          onClick={() => this.props.history.push("/contact")}
        >
          BOOK A DEMO
        </Button2>
      </div>
      <div>
        <img src="/public/images/IntranetPage/Engagement_image.png" />
      </div>
      <div>
        {/* <p>WHY USERS LOVE US</p> */}
        <h1>
          Simple and Customizable{this.state.mobileViewStatus ? "" : <br />}{" "}
          Employee Rewards:
        </h1>
        <img src="/public/images/HomePageV2/blueDivider.png" />
        <p>
          We personalize and automate your entire rewards
          {this.state.mobileViewStatus ? "" : <br />}
          program saving you time and headaches.
        </p>
        <Button2
          display={"block"}
          onClick={() => this.props.history.push("/contact")}
        >
          REQUEST A DEMO
        </Button2>
      </div>
    </TabData>
  );

  handleResources = (resIndex, rowIndex) => {
    const { wellnessResources, image } = this.state;
    let array = wellnessResources;
    wellnessResources[resIndex].data.forEach((info, index) => {
      array[resIndex].data[index].status = info.status === 1 && 0;
    });
    array[resIndex].data[rowIndex].status = 1;
    let images = image;
    images[resIndex] = wellnessResources[resIndex].data[rowIndex].image;
    this.setState({ wellnessResources: array, image: images });
  };

  multipleFeatures = () => (
    <ResourcesWrapper>
      {this.state.wellnessResources.length > 0 &&
        this.state.wellnessResources.map((row, index) => (
          <MainResources key={index} color={row.color}>
            {index === 1 && this.coreFeatureSection()}
            {row.heading && <h3>{row.heading}</h3>}
            {row.headingNew && <h3>{row.headingNew}</h3>}

            {index !== 0 && (
              <LazyImage div={"line"} src={ImageUrl + "/images/HomePageV2/Rectanglewellness.png"}/>
            )}
            <Resources
              flexDirection={row.swapRow}
              col={row.color}
              align={index % 2 === 0 ? "flex-start" : "flex-end"}
              padding={index === 0}
            >
              <div>
                <div>
                  {index !== 0 && <LazyImage src={ImageUrl +"/images/" + `${row.icon}`} alt={row.title}/>}
                  {index !== 0 && <h4 className={"title"}>{row.title}</h4>}
                </div>
                <div className="normal">
                  {row.data.map((info, rowindex) => (
                    <RowWrapperV2
                      key={rowindex}
                      selected={info.status}
                      bgColor={row.bgColor}
                      onClick={() => this.handleResources(index, rowindex)}
                      customColor={info.status === 1 ? 1 : 0}
                    >
                      <div className={"header"}>
                        <span><LazyImage src={ImageUrl +"/images/" + `${info.icon}`} alt={row.title}/></span>
                        <div>{info.header}</div>
                        <LazyImage div={"downArrow"} src={ImageUrl +"/images/WellnessPage/Vector (1).svg"} alt={"arrow"}/>

                      </div>
                      {info.status === 1 && (
                        <div className={"expand"}>{info.description}</div>
                      )}
                    </RowWrapperV2>
                  ))}
                </div>
                <div className="responsive">
                  {row.data.map((info, rowindex) => (
                    <div>
                      <RowResponsiveWrapperV2
                        key={rowindex}
                        selected={info.status}
                        bgColor={row.bgColor}
                        onClick={() => this.handleResources(index, rowindex)}
                        customColor={info.status === 1 ? 1 : 0}
                      >
                        <div className={"header"}>
                          <span><LazyImage src={ImageUrl +"/images/" + `${info.icon}`} alt={row.title}/></span>
                          <div>{info.header}</div>
                          <LazyImage div={"downArrow"} src={ImageUrl +"/images/WellnessPage/Vector (1).svg"} alt={"arrow"}/>

                        </div>
                        {info.status === 1 && (
                          <div className={"expand"}>{info.description}</div>
                        )}
                      </RowResponsiveWrapperV2>
                      {info.status === 1 && (
                        <LazyImage div={"responsive-image"} src={ImageUrl + "/images/" + this.state.image[index]}/>

                      )}
                    </div>
                  ))}
                </div>
              </div>
              <LazyImage div={"image"} src={ImageUrl + "/images/" + this.state.image[index]}/>

            </Resources>
          </MainResources>
        ))}
    </ResourcesWrapper>
  );

  coreFeatureSectionMain = () => (
    <CompanyRewardsProgram>
      <div>
        <h2>Customize Your Company Rewards Program</h2>
        <LazyImage src={ImageUrl + "/images/HomePageV2/line.svg"}/>
      </div>
    </CompanyRewardsProgram>
  );

  coreFeatureSection = () => (
    <CoreFeatureSectionHeadingV1>
      <div>
        <h2>More Reasons To Choose Woliba</h2>
        <p className="subtext">
          HR teams use Woliba Rewards in combination with Fitness, Social,
          Wellness, Health Assessment
          <br />
          and Surveys to holistically engage employees.
        </p>
        <LazyImage src={ImageUrl + "/images/HomePageV2/Rectanglewellness.png"}/>
      </div>
    </CoreFeatureSectionHeadingV1>
  );

  wellBeingSection = () => (
    <WellBeing background={"rgb(253, 113, 117)"}>
      <div style={{ background: "rgb(253, 113, 117)" }}>
        <div>
          <p style={{ marginBottom: 0 }}>
            Want to see the full <br />
            Woliba experience?
          </p>
          <DemoText>
            Request a demo to meet with one of our
            <br />
            wellness specialists.
          </DemoText>
          <ButtonGetStarted
            display={"block"}
            onClick={() => this.props.history.push("/contact")}
          >
            Schedule A Live Demo
          </ButtonGetStarted>
        </div>
      </div>
      <LazyImage src={ImageUrl + "/images/HomePageV2/Schedule_a_demo.webp"} />
    </WellBeing>
  );

  onEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  divider = () => (
    <Divider>
      <img src={`${imgPath}/WellnessPage/blueDivider.png`} alt={"breaker"} />
    </Divider>
  );

  renderResponsiveTab = () => (
    <ResponsiveTestimonialTab>
      {this.state.selectedTab > 0 ? this.state.selectedTab > 0 && (
        <LazyImage div="leftArrow" onClick={() => this.decreaseIndex()} src={ImageUrl + "/images/HomePageV2/leftArrow.png"} />
      ) : (
        <div className="leftArrow"></div>
      )}
      <div>
        <p>{this.state.tabArray[this.state.selectedTab]}</p>
      </div>
      {this.state.selectedTab < 2 && this.state.selectedTab < 2 && (
        <LazyImage div="rightArrow" onClick={() => this.increaseIndex()} src={ImageUrl + "/images/HomePageV2/rightArrow.png"} /> 
          
      )}
    </ResponsiveTestimonialTab>
  );

  watchDemoVideo = () => (
    <WatchDemoCard>
      <div className="topcard-demo-wrapper">
        <div className="topcard-demo">
          <div className="topcard-pic">
            <img
              className="enter-img"
              src="/public/images/request_a_demo.svg"
            ></img>
          </div>
          <div className="topcard-content">
            <div className="upper-content">Don’t ready to chat Yet?</div>
            <div className="lower-content">
              <p>See how Woliba can help you manage all of your</p>

              <p>employee wellness and Welbeing in one place And</p>

              <p>easily keep all your employee health in control</p>
            </div>
            <div className="lower-mobb-content">
              <p>
                See how Woliba can help you manage all of your employee wellness
                and Welbeing in one place And easily keep all your employee
                health in control
              </p>
            </div>
          </div>
          <button className="demo-btn" onClick={() => this.redirection()}>
            <div className="watch-text">Watch Recorded Demo</div>
          </button>
        </div>
      </div>
    </WatchDemoCard>
  );

  coreFeature = () => {
    let SubTabArray = [
      {
        tabName: "Company Analytics",
        image: "/path/",
      },
      {
        tabName: "Activity",
        image: "/path/",
      },
      {
        tabName: "Best Performer",
        image: "/path/",
      },
      {
        tabName: "Company Rewards",
        image: "/path/",
      },
    ];
    return (
      <CoreWrapper>
        <div>
          <h2>
            Keep Your Finger On The Pulse
            <br /> Of Performance, Culture And Collaboration
          </h2>
        </div>
        <div>
          <TabsContainerV2>
            <MenuListV2Main2>
              {SubTabArray.map((data, index) => (
                <CustomList
                  showTab={true}
                  active={this.state.selectedSubTab === index}
                  key={index}
                  onClick={() => this.handleSubTabs(index)}
                >
                  <MenuLinks>
                    <h3 style={{ margin: 0, fontSize: "20px" }}>
                      {data.tabName}
                    </h3>
                  </MenuLinks>
                </CustomList>
              ))}
            </MenuListV2Main2>
          </TabsContainerV2>
        </div>
        <div>
          <div>
            <div>
              <p>{this.state.coreInfo[this.state.selectedSubTab]}</p>
              <br />
              <p>{this.state.coreInfo2[this.state.selectedSubTab]}</p>
            </div>
            {/* <BookDemo onClick={() => this.wellnessPageRedirection(this.state.selectedSubTab)} 
              noMargin={1}>{this.state.coreButtonText[this.state.selectedSubTab]}
              <i className="fas fa-chevron-right" style={{"marginLeft": "10px"}}></i>
            </BookDemo> */}
          </div>
          <LazyImage src={ImageUrl + this.state.coreImage[this.state.selectedSubTab]}/>
        </div>
        <div className="responsive-core">
          {SubTabArray.map((data, index) => (
            <CoreListV2
              active={
                this.state.selectedCoreTab === index && this.state.showtab
                  ? 1
                  : 0
              }
              key={index}
              onClick={() => this.handleSubResponsiveTabs(index)}
            >
              <div style={{alignItems:"center"}}>
                <h3>{data.tabName}</h3>
                <LazyImage src={ImageUrl + "/images/HomePageV2/down.png"}/> 
              </div>
              {this.state.currentDiv[index] && this.state.showtab && (
                <div className="selectedCoreContent">
                  <div>
                    {this.state.coreHead[this.state.selectedCoreTab] && (
                      <h2>{this.state.coreHead[this.state.selectedCoreTab]}</h2>
                    )}
                    {/* <img src="/public/images/HomePageV2/Rectangle.png" /> */}
                    <div>
                      <p>{this.state.coreInfo[this.state.selectedCoreTab]}</p>
                      <br />
                      <p>{this.state.coreInfo2[this.state.selectedCoreTab]}</p>
                    </div>
                    {/* <ButtonBookDemo onClick={()=> this.wellnessPageRedirection(this.state.selectedCoreTab)} 
                      noMargin={1}>{this.state.coreButtonText[this.state.selectedCoreTab]}</ButtonBookDemo> */}
                  </div>
                  <LazyImage src={ImageUrl + this.state.coreImage[this.state.selectedSubTab]}/>
                </div>
              )}
              <LazyImage div={"coreDivider"} src={ImageUrl + "/images/HomePageV2/CoreDivider.png"}/>
            </CoreListV2>
          ))}
        </div>
      </CoreWrapper>
    );
  };

  employeeRewardCards = () => {
    let EmployeeReward = [
      {
        heading: "Applaud",
        image: "/images/RewardsWebImages/Applaud.svg",
        subTextOne: "Make employees feel",
        subTextTwo: "valued and appreciated",
        subTextThree: "",
      },
      {
        heading: "Align",
        image: "/images/RewardsWebImages/RewardsProgram.svg",
        subTextOne: "Align rewards with your company’s",
        subTextTwo: "values and business purpose",
      },
      {
        heading: "Act",
        image: "/images/RewardsWebImages/Redeem.svg",
        subTextOne: "Benefit from increased employee",
        subTextTwo: "retention, productivity and engagement",
      },
    ];

    return (
      <EmployeeRewardMain>
        {this.employeeRewardHeading()}
        <div className={"wrapper"}>
          {EmployeeReward.map((data, index) => (
            <EmployeeRewardCard key={index}>
              <LazyImage div={"image"} src={ImageUrl + data.image} alt={data.image}/>
              <p className="heading">{data.heading}</p>
              <div className="text-container">
                <p className="text">{data.subTextOne}</p>
                <p className="text">{data.subTextTwo}</p>
                <p className="text">{data.subTextThree}</p>
              </div>
            </EmployeeRewardCard>
          ))}
        </div>
      </EmployeeRewardMain>
    );
  };

  employeeRewardHeading = () => (
    <CoreFeatureSectionHeading margin="auto">
      <div>
        <h2>Employee Rewards Program with Woliba</h2>
        <p className="subtext">
          Nurture and retain your top talent with timely rewards.
        </p>
        <LazyImage src={ImageUrl + "/images/HomePageV2/Rectanglewellness.png"}/>
      </div>
    </CoreFeatureSectionHeading>
  );

  render() {
    const { showVideo } = this.state;

    return (
      <WrapperContainer>
        {this.top()}
        {this.employeeRewardCards()}
        <PaddingContainer>{this.coreFeatureSectionMain()}</PaddingContainer>
        <PaddingContainer>{this.multipleFeatures()}</PaddingContainer>
        {this.coreFeature()}
        {/* {this.watchDemoVideo()} */}
        {this.ratingshead()}
        {this.ratingsUI()}
        {this.renderResponsiveTab()}
        <PaddingContainer>{this.renderMenuList()}</PaddingContainer>
        {this.wellBeingSection()}
        {showVideo && (
          <Suspense fallback={<Waiting />}>
            <DemoPopup
              showModal={showVideo}
              onClose={this.changeModal}
              videoUrl={"https://vimeo.com/547521670"}
              VideoTitle={"Woliba Corporate Wellness"}
            />
          </Suspense>
        )}
      </WrapperContainer>
    );
  }
}
RewardPageMain.propTypes = {
  history: PropTypes.object,
};

export default RewardPageMain;
